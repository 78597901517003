import React from "react";
import Spinner from "react-spinkit";
import styled from "styled-components";

const ContainerDiv = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function LoadingScreen() {
  return (
    <ContainerDiv>
      <Spinner name="line-scale" />
    </ContainerDiv>
  );
}
