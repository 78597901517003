import React from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { ITeaser, IRouteParams } from "../../../domain";
import { IContentInset } from "../../../layout";
import BasicTeaser from "./BasicTeaser";
import ImageTeaser from "./ImageTeaser";
import { TeaserLink } from "./Teaser.styled";
import TitleTeaser from "./TitleTeaser";
import ThreeSixyTeaser from "./ThreeSixyTeaser";

interface IProps {
  teaser: ITeaser;
  contentInset: IContentInset;
  isLastInColumn: boolean;
  sectionColor: string;
  shouldBeVisible: boolean;
}

export default function Teaser(props: IProps) {
  const history = useHistory();
  const { pageId } = useParams<IRouteParams>();
  const { teaser, shouldBeVisible } = props;

  const onClick = () => {
    if (teaser.articleId) {
      history.push(`/${pageId}/${teaser.articleId}`);
    }
  };

  return (
    <TeaserLink onClick={onClick} className="teaser-link">
      {teaser._type === "basic" && (
        <BasicTeaser
          teaser={teaser}
          sectionColor={props.sectionColor}
          contentInset={props.contentInset}
          shouldBeVisible={shouldBeVisible}
        />
      )}
      {teaser._type === "image" && (
        <ImageTeaser
          teaser={teaser}
          sectionColor={props.sectionColor}
          contentInset={props.contentInset}
          shouldBeVisible={shouldBeVisible}
        />
      )}
      {teaser._type === "360" && (
        <ThreeSixyTeaser
          teaser={teaser}
          sectionColor={props.sectionColor}
          contentInset={props.contentInset}
          shouldBeVisible={shouldBeVisible}
        />
      )}
      {teaser._type === "title" && (
        <TitleTeaser
          teaser={teaser}
          sectionColor={props.sectionColor}
          contentInset={props.contentInset}
          isLastInColumn={props.isLastInColumn}
        />
      )}
    </TeaserLink>
  );
}
