import React from "react";
import { ITitleTeaser } from "../../../domain";
import { IContentInset } from "../../../layout";
import { ContainerDiv, TitleStyledText } from "./TitleTeaser.styled";

interface IProps {
  teaser: ITitleTeaser;
  contentInset: IContentInset;
  isLastInColumn: boolean;
  sectionColor: string;
}

export default function TitleTeaser({ teaser, contentInset, isLastInColumn, sectionColor }: IProps) {
  return (
    <ContainerDiv contentInset={contentInset} isLastInColumn={isLastInColumn}>
      {teaser.title && <TitleStyledText isTeaser={true} sectionColor={sectionColor} text={teaser.title} />}
    </ContainerDiv>
  );
}
