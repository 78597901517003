import React from "react";
import styled from "styled-components";
import { IArticleSidebarStatisticContent, IColor } from "../../../domain";
import { rValue } from "../../../layout";
import LocalizedStyledText from "../../shared/LocalizedStyledText";
import SidebarContent from "./SidebarContent";

interface IProps {
  content: IArticleSidebarStatisticContent;
  sectionColor: IColor;
}

const NumberLocalizedStyledText = styled(LocalizedStyledText)`
  ${rValue("font-size", ["84px", "84px", "84px", "124px"])};
  font-weight: bold;
  color: ${(p: { sectionColor: IColor }) => p.sectionColor};
  line-height: 100%;
  margin-bottom: 0.2em;
`;

const TextLocalizedStyledText = styled(LocalizedStyledText)`
  ${rValue("font-size", ["24px", "24px", "24px", "30px"])};
  line-height: 1.4;
`;

const BarDiv = styled.div`
  background: ${(p: { sectionColor: IColor }) => p.sectionColor};
  ${rValue("width", ["148px", "148px", "148px", "220px"])};
  ${rValue("height", ["24px", "24px", "24px", "36px"])};
  margin-top: 20px;
`;

export default function SidebarStatisticContent(props: IProps) {
  return (
    <SidebarContent>
      <NumberLocalizedStyledText text={props.content.number} sectionColor={props.sectionColor} />
      <TextLocalizedStyledText text={props.content.text} />
      <BarDiv sectionColor={props.sectionColor} />
    </SidebarContent>
  );
}
