import styled from "styled-components";
import { FontFamily, IColor } from "../../domain";
import { responsiveValue, rValue } from "../../layout";
import PlainText from "../shared/PlainText";
import { headerHeight, pageGutter } from "./layout";

export const ContainerDiv = styled.div`
  position: sticky;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100vw;
`;

export const TitlePlainText = styled(PlainText)`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  color: white;
  font-family: ${FontFamily.sharpSansDisplay};
  font-weight: 400;
  font-style: normal;
  ${rValue("font-size", ["24px", "17px", "24px", "24px"])}
  letter-spacing: -0.4px;
  background: ${({ tintColor }: { tintColor: IColor }) => tintColor};
  min-width: 25vw;
  padding-top: 14px;
  padding-right: 2rem;
  padding-bottom: 14px;
  ${responsiveValue("padding-left", pageGutter)};
`;

interface IInnerContentDiv {
  tintColor: IColor;
  hideSectionTitle: boolean;
}

export const InnerContentDiv = styled.div<IInnerContentDiv>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-right: ${({ tintColor }: { tintColor: IColor }) => `20px solid ${tintColor}`};
  padding-right: 14px;
  ${responsiveValue("height", headerHeight)};

  ${TitlePlainText} {
    visibility: ${({ hideSectionTitle }: { hideSectionTitle: boolean }) => hideSectionTitle ? 'hidden' : 'visible'};
  }
`;
