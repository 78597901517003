import React from "react";
import { IOverviewSection } from "../../domain";
import LanguageSwitch from "../LanguageSwitch";
import * as Styled from "./Header.styled";
import Color from "../../colors";

interface IProps {
  section: IOverviewSection;
  hideSectionTitle: boolean;
  textColor: Color;
}

export default function SectionHeader(props: IProps) {
  const { color, title } = props.section;

  return (
    <Styled.ContainerDiv>
      <Styled.InnerContentDiv tintColor={color} hideSectionTitle={props.hideSectionTitle}>
        <Styled.TitlePlainText tintColor={color} text={title} />
        <LanguageSwitch textColor={props.textColor} />
      </Styled.InnerContentDiv>
    </Styled.ContainerDiv>
  );
}
