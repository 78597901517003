import ColorUtil from "color";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import Color from "../../colors";
import { IChart } from "../../domain";

interface IProps {
  chart: IChart;
}

export default function Chart(props: IProps) {
  const { values } = props.chart;

  return (
    <Doughnut
      data={{
        labels: values.map(({ label }) => label),
        datasets: [
          {
            data: values.map(({ value }) => value),
            backgroundColor: values.map((_, index) => colorForIndex(index)),
          },
        ],
      }}
    />
  );
}

function colorForIndex(index: number): string {
  const colors = [Color.Purple, Color.Red, Color.Pink, Color.Yellow];
  const color = colors[index % colors.length];
  return ColorUtil(color)
    .lighten(Math.floor(index / colors.length) * 0.2)
    .string();
}
