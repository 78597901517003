import React, { useContext } from "react";
import LanguageContext from "../../context/LanguageContext";
import { IArticle, IArticleContent, IArticleContentHeader, Language } from "../../domain";
import * as Styled from "./Content.styled";
import ChartContent from "./content/ChartContent";
import EmbedContent from "./content/EmbedContent";
import ImageContent from "./content/ImageContent";
import SidebarImageContent from "./content/SidebarImageContent";
import SidebarQuoteContent from "./content/SidebarQuoteContent";
import SidebarStatisticContent from "./content/SidebarStatisticContent";
import TableContent from "./content/TableContent";
import TextContent from "./content/TextContent";
import Header from "./Header";
import { ArticleState } from "./utils";
import IntroTextContent from "./content/IntroTextContent";

interface IProps {
  onCloseArticle: () => void;
  article: IArticle;
  articleState: ArticleState;
  sectionColor: string;
  pageId: string;
}

const makeContentWithArticleHeader = (props: IProps, language: Language) =>
  props.article.header.isArticleView2 && props.article.header.intro
    ? [
        {
          _type: "intro",
          text: props.article.header.intro[language],
        } as IArticleContentHeader,
        ...props.article.content[language],
      ]
    : props.article.content[language];

const Content = React.memo((props: IProps) => {
  const { sectionColor, pageId, onCloseArticle } = props;
  const { language } = useContext(LanguageContext);
  const articleContent = makeContentWithArticleHeader(props, language);

  const renderContent = (content: IArticleContent) => {
    switch (content._type) {
      case "intro":
        return <IntroTextContent content={content} />;
      case "image":
        return <ImageContent content={content} />;
      case "text":
        return <TextContent content={content} />;
      case "table":
        return <TableContent content={content} />;
      case "embed":
        return <EmbedContent content={content} />;
      case "chart":
        return <ChartContent content={content} />;
      case "sidebar-quote":
        return <SidebarQuoteContent content={content} />;
      case "sidebar-statistic":
        return <SidebarStatisticContent content={content} sectionColor={sectionColor} />;
      case "sidebar-image":
        return <SidebarImageContent content={content} />;
      case "hr":
        return content.width === "double" ? <hr className="article-double" /> : <hr className="article-single" />;
      }
  };

  return (
    <Styled.ContainerDiv>
      <Header
        onCloseArticle={onCloseArticle}
        header={props.article.header}
        pageId={pageId}
        sectionColor={sectionColor}
      />
      <Styled.ContentItemsDiv>
        {articleContent.map((content, index) => (
          <Styled.ContentItemDiv key={index}>{renderContent(content)}</Styled.ContentItemDiv>
        ))}
      </Styled.ContentItemsDiv>
    </Styled.ContainerDiv>
  );
});

export default Content;
