import React, { useContext, useEffect, useRef, useState } from "react";
import LanguageContext from "../../context/LanguageContext";
import { IOverviewSection } from "../../domain";
import * as Styled from "./Navigation.styled";
import { NavigationReason, SetPage } from "./types";

interface IProps {
  sections: IOverviewSection[];
  currentPageId: string;
  setPage: SetPage;
}

export default function Navigation(props: IProps) {
  const { sections, currentPageId } = props;
  const currentSection = sections.find(section => section.pages.findIndex(page => page._id === currentPageId) > -1);
  const containerRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [scrollBehavior, setScrollBehavior] = useState<ScrollOptions["behavior"]>("auto");

  const defaultItemWidth = 1024/sections.length;
  const [itemWidth, setItemWidth] = useState(defaultItemWidth);

  const currentSectionIndex = sections.findIndex(section => section._id === currentSection!._id);
  const currentSectionColor = currentSection?.color;
  const currentPageIndex = currentSection!.pages.findIndex(page => page._id === currentPageId);
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    const container = containerRef.current;
    if (container !== undefined) {
      const spacing = (window.innerWidth - itemWidth) / 2;
      const targetScrollLeft = currentSectionIndex * itemWidth - spacing;
      container.scrollTo({ left: targetScrollLeft, behavior: scrollBehavior });
    }
  }, [currentSectionIndex, itemWidth, containerRef, sections.length, scrollBehavior]);

  useEffect(() => {
    setScrollBehavior("smooth");
  }, []);

  useEffect(() => {
    function handleResize() {
      setItemWidth(Math.max(window.innerWidth / (sections.length-0.5), defaultItemWidth));
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [sections.length, defaultItemWidth]);

  return (
    <Styled.ContainerDiv ref={containerRef}>
      <Styled.ProgressDiv
        currentSectionIndex={currentSectionIndex}
        amountOfPages={currentSection!.pages.length}
        currentPageIndex={currentPageIndex}
        itemWidth={itemWidth}
        currentSectionColor={currentSectionColor}
      />

      <Styled.ItemsDiv>
        {sections.map((section, sectionIndex) => (
          <Styled.ItemButton
            key={section._id}
            width={sectionIndex === 0 ? itemWidth/2 : itemWidth}
            onClick={() => {
              if (currentPageId !== section.pages[0]._id) {
                props.setPage(section.pages[0]._id, NavigationReason.button);
              }
            }}
          >
            {sectionIndex !== 0 && section.navigation[language]}
            {sectionIndex === 0 && <Styled.LogoImg src="/static_assets/dpg-logo-small.svg" alt="logo" />}
          </Styled.ItemButton>
        ))}
      </Styled.ItemsDiv>
    </Styled.ContainerDiv>
  );
}
