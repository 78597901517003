import React, { useState, useEffect } from "react";
import useEventListener from "@use-it/event-listener";
import * as Styled from "./ScrollIndicator.styled";

interface Props {
  color: string;
  isVisible: boolean;
}

let isScrolling: any;
function onScrollWithCB(cb: any) {
  return function(event: any) {
    window.clearTimeout(isScrolling);
    isScrolling = setTimeout(function() {
      cb(event);
    }, 66);
  };
}

const ScrollIndicator = React.memo(({ color, isVisible }: Props) => {
  const [scrollProgress, setScrollProgress] = useState(0);
  useEventListener("scroll", onScrollWithCB(onScroll), document.getElementById("article-content-container")!);

  function onScroll(e: any) {
    const el = e.target;
    const winScroll = el.scrollTop;
    const height = el.scrollHeight - el.clientHeight;
    const scrolled = Math.round((winScroll / height) * 100);

    if (scrolled < 0) {
      return;
    }

    setScrollProgress(scrolled);
  }

  useEffect(() => {
    if (!isVisible) {
      setScrollProgress(0);
    }
  }, [isVisible]);

  return (
    <Styled.ContainerDiv isVisible={isVisible}>
      <Styled.SectionHeaderPlaceholder color={color} />
      <Styled.ProgressBarContainerDiv>
        <Styled.ProgressBarDiv widthPercentage={scrollProgress} color={color} />
      </Styled.ProgressBarContainerDiv>
    </Styled.ContainerDiv>
  );
});

export default ScrollIndicator;
