import styled from "styled-components";
import { responsiveValue, zeroResponsiveValue } from "../../../layout";
import { headerHeight } from "../layout";

export const ContainerDiv = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  ${(p: { underneathHeader: boolean }) => {
    return responsiveValue("padding-top", p.underneathHeader ? zeroResponsiveValue : headerHeight);
  }}
`;
