import styled from "styled-components";
import { responsiveValue } from "../../../layout";
import { navigationHeight } from "../layout";

export interface IContainerDivProps {
  size: number;
  align: "top" | "center" | "stretch";
}

export const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  overflow-y: auto;
  ${responsiveValue("padding-bottom", navigationHeight)};
  width: ${(p: IContainerDivProps) => `${p.size * 100}%`};
  justify-content: ${(p: IContainerDivProps) =>
    ({
      top: "flex-start",
      center: "center",
      stretch: "stretch",
    }[p.align])};
`;

interface ITeaserDivProps {
  flexGrow?: number;
}

export const TeaserDiv = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  flex-grow: ${(p: ITeaserDivProps) => p.flexGrow};
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
`;
