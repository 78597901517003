import { createContext } from "react";
import { Language, DefaultLanguage } from "../domain";
import { isEn } from "../routing";

interface IContext {
  language: Language;
  setLanguage: (language: Language) => void;
}

export default createContext<IContext>({
  language: isEn(window.location.hostname) ? Language.en : DefaultLanguage,
  setLanguage: () => {},
});
