import styled from "styled-components";
import { rValue } from "../../../layout";
import Color from "../../../colors";
import { FontFamily } from "../../../domain";

export const ContainerDiv = styled.div`
  h2 {
    font-family: ${FontFamily.sharpSansDisplay} !important;
    line-height: 1.15;
    font-size: 42px;
    font-weight: 500;
    letter-spacing: 1.8px;
    margin-top: 0;
    margin-bottom: 28px;
    ${rValue("font-size", ["23px", "42px", "42px", "60px"])}
  
    strong {
      font-size: 1.1em;
      font-weight: bold;
      letter-spacing: -1px;
      line-height: 0.9;
    }
  }

  h5 {
    font-family: ${FontFamily.freightTextPro} !important;
    line-height: 1.6em;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: -28px;
    ${rValue("font-size", ["20px", "20px", "22px", "24px"])}
  }

  p {
    margin-bottom: 40px;
    line-height: 1.6em;
    ${rValue("font-size", ["18px", "18px", "20px", "22px"])}
  }

  .preformatted {
    background-color: ${Color.Grey};
    ${rValue("padding", ["20px", "40px", "40px", "40px"])}

    h3 {
      ${rValue("font-size", ["26px", "26px", "26px", "28px"])}
      font-weight: bold;
      margin-bottom: 12px;
    }

    p {
      margin-bottom: 16px;
    }
  }
`;
