import styled from "styled-components";
import { ReactComponent as Arrow } from "../shared/Arrow.svg";

export const ContainerDiv = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  transition: opacity 0.2s;
  opacity: ${(p: { isVisible: boolean }) => (p.isVisible ? 1 : 0)};

  &[data-show] {
    opacity: 1;
  }
`;

const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: auto;
  background: rgba(230, 230, 230, 0.5);
  font-size: 2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  width: 50px;
  height: 50px;
  overflow: hidden;
  opacity: ${(p: { isDisabled: boolean }) => (p.isDisabled ? 0 : 1)};

  &.nudge {
    opacity: 1;
    background: rgba(230, 230, 230, .75);
    svg {
      animation: nudge .5s ease-out infinite;
      margin: 0;
    }
  }

  &:hover {
    background: rgba(230, 230, 230, .75);
  }
`;

export const NextArrowButton = styled(ArrowButton)`
  right: 0;
`;

export const PreviousArrowButton = styled(ArrowButton)`
  left: 0;
`;

const ArrowSvg = styled(Arrow)`
  height: 60%;
  transition: all .3s ease-out .2s;
`;

export const NextArrowSvg = styled(ArrowSvg)`
  margin-left: ${(p: { isVisible: boolean }) => (p.isVisible ? 0 : 50)}px;
`;

export const PreviousArrowSvg = styled(ArrowSvg)`
  margin-right: ${(p: { isVisible: boolean }) => (p.isVisible ? 0 : 50)}px;
  transform: rotate(180deg);
  transform-origin: center center;
`;
