import React, { useContext } from "react";
import LanguageContext from "../../context/LanguageContext";
import { Language } from "../../domain";
import * as Styled from "./ErrorScreen.styled";

interface IProps {
  error: Error;
}

export default function ErrorScreen(props: IProps) {
  const { language } = useContext(LanguageContext);

  return (
    <Styled.ContainerDiv>
      <Styled.TitleH1>
        {
          {
            [Language.nl]: "Er is iets misgegaan",
            [Language.en]: "Something went wrong",
          }[language]
        }
      </Styled.TitleH1>

      <Styled.DescriptionP>{props.error.message}</Styled.DescriptionP>
      
      <Styled.DescriptionP>&nbsp;</Styled.DescriptionP>
      
      <Styled.DescriptionP><a href="/">
      {
          {
            [Language.nl]: "Ga naar de homepage",
            [Language.en]: "Go to the homepage",
          }[language]
        }
        </a></Styled.DescriptionP>
    </Styled.ContainerDiv>
  );
}
