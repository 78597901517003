import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Redirect, useHistory, useParams } from "react-router";
import { getOverview } from "../../data";
import { IRouteParams } from "../../domain";
import ErrorScreen from "../shared/ErrorScreen";
import LoadingScreen from "../shared/LoadingScreen";
import ArrowNavigation from "./ArrowNavigation";
import Navigation from "./Navigation";
import Sections from "./Sections";
import { NavigationReason, SetPage } from "./types";
import { analytics } from "../../global-scripts/firebase";

export default function OverviewPage() {
  const params = useParams<IRouteParams>();
  const history = useHistory();
  const { data, isLoading, error } = useQuery("overview", getOverview);
  const [navigationReason, setNavigationReason] = useState<NavigationReason | undefined>();

  const setPage: SetPage = (pageId, newNavigationReason) => {
    if (navigationReason) {
      // Don't do anything while we're still navigating
      return;
    }

    // Store the reason to navigate. This way scroll-events (with new setPage calls) will be
    // ignored when navigating with buttons (which  causes the scroll to change - animated):
    setNavigationReason(newNavigationReason);

    // Update url
    const { articleId } = params as any;
    if (!articleId) {
      analytics.logEvent(`/${pageId}`);
      // analytics.setCurrentScreen(`/${pageId}`);
      history.replace(`/${pageId}`);
    }
  };

  useEffect(() => {
    document.body.id = "overview-page";
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <ErrorScreen error={error} />;
  }

  if (!data) {
    return <ErrorScreen error={new Error("No data")} />;
  }


  const pages = data.sections.flatMap(s => s.pages);
  const currentPageId = params.pageId;
  const currentPageIndex = pages.findIndex(page => page._id === currentPageId);

  if (!currentPageId) {
    return <Redirect to={`/${pages[0]._id}`} />;
  }

  if (currentPageIndex < 0) {
    return <ErrorScreen error={new Error("Page not found")} />;
  }

  return (
    <div>
      <Sections
        sections={data.sections}
        pages={pages}
        currentPageId={currentPageId}
        setPage={setPage}
        navigationReason={navigationReason}
        onChangePage={() => setNavigationReason(undefined)}
      />
      <Navigation sections={data.sections} currentPageId={currentPageId} setPage={setPage} />
      <ArrowNavigation pages={pages} currentPageIndex={currentPageIndex} setPage={setPage} />
    </div>
  );
}
