import React from "react";
import * as Styled from "./EmbedContent.styled";
import { IArticleEmbedContent } from "../../../domain";

interface IProps {
  content: IArticleEmbedContent;
}

export default function EmbedContent(props: IProps) {
  const { url, width, height } = props.content;

  return (
    <Styled.ContainerDiv aspectRatio={width && height ? height / width : 9 / 16}>
      <Styled.Frame src={url} />
    </Styled.ContainerDiv>
  );
}
