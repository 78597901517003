import styled from "styled-components";
import { rValue } from "../../../layout";

export const ContainerDiv = styled.div`
  p {
    font-weight: bold;
    margin-bottom: 40px;
    ${rValue("font-size", ["22px", "26px", "28px", "32px"])}
  }
`;
