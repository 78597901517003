import React, { useContext } from "react";
import LanguageContext from "../../context/LanguageContext";
import { IStyledText, FontFamily } from "../../domain";
import LocalizedStyledText from "./LocalizedStyledText";

export interface IProps {
  text: IStyledText;
  color?: string;
  style?: React.CSSProperties;
  labelColor?: string;
  boldWordColor?: string;
  className?: string;
  isTeaser?: boolean;
  font?: FontFamily;
  size?: "small" | "large";
  sectionColor?: string;
  fontFamily?: FontFamily;
}

export default function StyledText(props: IProps) {
  const { language } = useContext(LanguageContext);
  const { text, ...otherProps } = props;
  return <LocalizedStyledText {...otherProps} text={text[language]} />;
}
