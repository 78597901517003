
import styled from "styled-components";
import { responsiveValue } from "../../layout";
import { navigationHeight, pageGutter } from "./layout";

export const SectionsDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  overflow: auto;
  // overflow-x: hidden;
  scroll-snap-type: x mandatory;
`;

export const SectionDiv = styled.div``;

export const PagesDiv = styled.div`
  display: flex;
  height: 100%;
`;

export const PageDiv = styled.div`
  scroll-snap-align: center;
  width: 100vw;
  height: 100%;
`;

export const LogoImg = styled.img`
  position: absolute;
  ${responsiveValue("left", pageGutter)};
  ${responsiveValue("top", pageGutter)};
  width: 10%;
  z-index: 10;
`;

export const LogosWrapperDiv = styled.div`
  position: absolute;
  top: 0;
  right 0;
  bottom: 0;
  left: 0;
  width:100%;
  height: 100%;
  overflow: hidden;
`;

export const LogosInnerDiv = styled.div`
  position: absolute;
  top: 0;
  right 0;
  bottom: 0;
  left: 0;
  width:100%;
  height: 100%;
  animation: visual 40s ease-in-out infinite;
  ${responsiveValue("padding-bottom", navigationHeight)};
`;

export const LogosDiv = styled.div`
  width:100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: bottom;
  background-image: url("/static_assets/logotjes.png");
  animation: fadeIn 3s ease-in-out;
`;