import YouTube from "react-youtube";
import styled from "styled-components";
import { IThreeSixyTeaser } from "../../../domain";
import {
  horizontalAlignmentToFlexbox,
  IContentInset,
  responsiveValue,
  rValue,
  verticalAlignmentToFlexbox,
} from "../../../layout";
import StyledText from "../../shared/StyledText";
import { teaserInset, bottomGutter } from "../layout";
import { IProps as IStyledTextProps } from "../../shared/StyledText";

interface IContainerDivProps {
  teaser: IThreeSixyTeaser;
}

export const ContainerDiv = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: ${(p: IContainerDivProps) => horizontalAlignmentToFlexbox(p.teaser.horizontalTextPosition)};
  justify-content: ${(p: IContainerDivProps) => verticalAlignmentToFlexbox(p.teaser.verticalTextPosition)};
  background-size: cover;
  background-repeat: no-repeat;
`;

interface ITextContainerDivProps {
  teaser: IThreeSixyTeaser;
  contentInset: IContentInset;
}

export const TextContainerDiv = styled.div`
  ${teaserInset};
  position: relative;
  z-index: 1;
  width: ${(p: ITextContainerDivProps) => `${p.teaser.textWidth * 100}%`};
  display: flex;
  flex-direction: column;
  align-items: ${(p: ITextContainerDivProps) => horizontalAlignmentToFlexbox(p.teaser.textAlignment)};
  color: ${(p: ITextContainerDivProps) => p.teaser.textColor};
`;

const Text = styled(StyledText)`
  ${responsiveValue("margin-bottom", bottomGutter)};
  text-align: ${(p: { teaser: IThreeSixyTeaser }) => p.teaser.textAlignment};
  line-height: 120%;

  strong {
    color: ${(props: IStyledTextProps) => props.sectionColor};
  }
`;

export const LabelText = styled(Text)`
  ${rValue("font-size", ["16px", "18px", "18px", "22px"])}
`;

export const TitleText = styled(Text)`
  strong {
    color: ${(props: IStyledTextProps) => props.sectionColor};
    line-height: 1rem;
  }
`;

export const BodyText = styled(Text)`
  ${rValue("font-size", ["18px", "20px", "24px", "30px"])}
`;

export const StyledYouTube = styled(YouTube)`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
