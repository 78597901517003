import React, { useContext } from "react";
import LanguageContext from "../context/LanguageContext";
import { Language } from "../domain";
import { ContainerDiv, LanguageButton, SeparatorDiv } from "./LanguageSwitch.styled";
import Color from "../colors";
import { Urls, isAcc, isProd } from "../routing";

interface IProps {
  textColor: Color;
}

export default function LanguageSwitch(props: IProps) {
  const { language, setLanguage } = useContext(LanguageContext);
  const changeLanguage = (desiredLang: Language) => {
    const h = window.location.hostname;
    if (isProd(h)) {
      if (h !== Urls.prod[desiredLang]) {
        window.location.href = "https://" + Urls.prod[desiredLang];
      }
    }
    else if (isAcc(h)) {
      if (h !== Urls.acc[desiredLang]) {
        window.location.href = "https://" + Urls.acc[desiredLang];
      }
    }
    else {
      setLanguage(desiredLang);
    }
  };

  return (
    <ContainerDiv>
      <LanguageButton isActive={language === Language.nl} onClick={() => changeLanguage(Language.nl)} textColor={props.textColor}>
        NL
      </LanguageButton>
      <SeparatorDiv textColor={props.textColor} />
      <LanguageButton isActive={language === Language.en} onClick={() => changeLanguage(Language.en)} textColor={props.textColor}>
        EN
      </LanguageButton>
    </ContainerDiv>
  );
}
