import styled from "styled-components";
import Color from "../../colors";
import { srValue } from "../../layout";
import { contentColumnWidths } from "./utils";

export const ContainerDiv = styled.div`
  margin-bottom: 400px;
`;

export const ContentItemsDiv = styled.div`
  ${srValue("margin-left", [92, 122, 287, 406])}
  ${srValue("width", contentColumnWidths)};
  color: ${Color.Black};
`;

export const ContentItemDiv = styled.div`
  margin-bottom: 32px;
`;

export const CloseButton = styled.button`
  position: fixed;
  top: 16px;
  left: 16px;
  width: 16px;
  height: 16px;
  z-index: 2;
  background-color: white;
`;
