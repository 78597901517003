import React from "react";
import { GridLayout, IGrid, ITeaser } from "../../../domain";
import { LeftInset, RightInset, TopInset } from "../../../layout";
import { Column } from "./Column";
import { ContainerDiv } from "./Grid.styled";

interface IProps {
  grid: IGrid;
  sectionColor: string;
  shouldBeVisible: boolean;
}

export default function Grid({ grid, sectionColor, shouldBeVisible }: IProps) {
  const { layout, teasers } = grid;
  switch (layout) {
    case GridLayout.A:
      return GridA(teasers, sectionColor, shouldBeVisible);
    case GridLayout.B:
      return GridB(teasers, sectionColor, shouldBeVisible);
    case GridLayout.C:
      return GridC(teasers, sectionColor, shouldBeVisible);
    case GridLayout.D:
      return GridD(teasers, sectionColor, shouldBeVisible);
    case GridLayout.Cover:
      return Cover();
  }
}

// Dit is de plek waar je de custom styling van de cover kan selecteren
// als de designer de optie "cover" selecteert als grid van de pagina
function Cover() {
  return null;
}

function GridA(teasers: ITeaser[][], sectionColor: string, shouldBeVisible: boolean) {
  return (
    <ContainerDiv underneathHeader>
      <Column
        contentInset={{ top: TopInset.header, right: RightInset.page, left: LeftInset.page }}
        size={1}
        align="stretch"
        teasers={teasers.flat()}
        sectionColor={sectionColor}
        shouldBeVisible={shouldBeVisible}
      />
    </ContainerDiv>
  );
}

function GridB(teasers: ITeaser[][], sectionColor: string, shouldBeVisible: boolean) {
  return (
    <ContainerDiv underneathHeader={false}>
      <Column
        contentInset={{ top: TopInset.default, right: RightInset.default, left: LeftInset.page }}
        size={0.25}
        align="top"
        teasers={teasers[0]}
        sectionColor={sectionColor}
        shouldBeVisible={shouldBeVisible}
      />
      <Column
        contentInset={{ top: TopInset.default, right: RightInset.default, left: LeftInset.default }}
        size={0.5}
        align="stretch"
        teasers={teasers[1]}
        sectionColor={sectionColor}
        shouldBeVisible={shouldBeVisible}
      />
      <Column
        contentInset={{ top: TopInset.default, right: RightInset.page, left: LeftInset.default }}
        size={0.25}
        align="top"
        teasers={teasers[2]}
        sectionColor={sectionColor}
        shouldBeVisible={shouldBeVisible}
      />
    </ContainerDiv>
  );
}

function GridC(teasers: ITeaser[][], sectionColor: string, shouldBeVisible: boolean) {
  const hasImageAsFirstTeaser = [teasers[0], teasers[1]].flat().findIndex((t) => t._type === "image") > -1;

  return (
    <ContainerDiv underneathHeader={hasImageAsFirstTeaser}>
      <Column
        contentInset={{
          top: hasImageAsFirstTeaser ? TopInset.header : TopInset.default,
          right: RightInset.default,
          left: LeftInset.page,
        }}
        size={0.75}
        align="stretch"
        teasers={[teasers[0], teasers[1]].flat()}
        sectionColor={sectionColor}
        shouldBeVisible={shouldBeVisible}
      />
      <Column
        contentInset={{
          top: hasImageAsFirstTeaser ? TopInset.header : TopInset.default,
          right: RightInset.page,
          left: LeftInset.default,
        }}
        size={0.25}
        align="top"
        teasers={teasers[2]}
        sectionColor={sectionColor}
        shouldBeVisible={shouldBeVisible}
      />
    </ContainerDiv>
  );
}

function GridD(teasers: ITeaser[][], sectionColor: string, shouldBeVisible: boolean) {
  return (
    <ContainerDiv underneathHeader={false}>
      <Column
        contentInset={{ top: TopInset.default, right: RightInset.default, left: LeftInset.page }}
        size={0.25}
        align="top"
        teasers={teasers[0]}
        sectionColor={sectionColor}
        shouldBeVisible={shouldBeVisible}
      />
      <Column
        contentInset={{ top: TopInset.default, right: RightInset.page, left: LeftInset.default }}
        size={0.75}
        align="stretch"
        teasers={[teasers[1], teasers[2]].flat()}
        sectionColor={sectionColor}
        shouldBeVisible={shouldBeVisible}
      />
    </ContainerDiv>
  );
}
