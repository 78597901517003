import React from "react";
import styled from "styled-components";
import { IArticleSidebarImageContent } from "../../../domain";
import SidebarContent from "./SidebarContent";

interface IProps {
  content: IArticleSidebarImageContent;
}

const Image = styled.img`
  width: 100%;
  height: auto;
`;

export default function SidebarImageContent(props: IProps) {
  if (props.content.delighter) {
    return (
      <SidebarContent>
        <Image data-delighter={props.content.delighter} src={props.content.image.src} alt={props.content.image.alt} />
      </SidebarContent>
    );  
  }
  return (
    <SidebarContent>
      <Image src={props.content.image.src} alt={props.content.image.alt} />
    </SidebarContent>
  );
}
