import { HorizontalAlignment, VerticalAlignment } from "./domain";

export enum TopInset {
  default = "default",
  header = "header",
}

export enum LeftInset {
  default = "default",
  page = "page",
}

export enum RightInset {
  default = "default",
  page = "page",
}

export interface IContentInset {
  top: TopInset;
  right: RightInset;
  left: LeftInset;
}

export enum BreakPoint {
  // TODO: xs = "xs", // Phones
  s = "s", // Small tablets
  m = "m", // Tablets
  l = "l", // Desktop
  xl = "xl", // Huge monitors
}

export const rawBreakPoints = {
  [BreakPoint.s]: 768,
  [BreakPoint.m]: 1024,
  [BreakPoint.l]: 1440,
  [BreakPoint.xl]: 1920,
};

export const breakPoints: IResponsiveValue = {
  [BreakPoint.s]: `${rawBreakPoints.s}px`,
  [BreakPoint.m]: `${rawBreakPoints.m}px`,
  [BreakPoint.l]: `${rawBreakPoints.l}px`,
  [BreakPoint.xl]: `${rawBreakPoints.xl}px`,
};

export interface IResponsiveValue {
  [BreakPoint.s]: string;
  [BreakPoint.m]: string;
  [BreakPoint.l]: string;
  [BreakPoint.xl]: string;
}

export const zeroResponsiveValue: IResponsiveValue = {
  [BreakPoint.s]: "0px",
  [BreakPoint.m]: "0px",
  [BreakPoint.l]: "0px",
  [BreakPoint.xl]: "0px",
};

export const media = (breakPoint: BreakPoint) => `@media (min-width: ${breakPoints[breakPoint]})`;

export const responsiveValue = (property: string, value: IResponsiveValue) => {
  return `
    ${property}: ${value[BreakPoint.s]};

    ${media(BreakPoint.m)} {
      ${property}: ${value[BreakPoint.m]};
    }

    ${media(BreakPoint.l)} {
      ${property}: ${value[BreakPoint.l]};
    }

    ${media(BreakPoint.xl)} {
      ${property}: ${value[BreakPoint.xl]};
    }
  `;
};

// Shorthand version of responsiveValue()

export const rValue = (property: string, values: [string, string, string, string]) => {
  return responsiveValue(property, {
    [BreakPoint.s]: values[0],
    [BreakPoint.m]: values[1],
    [BreakPoint.l]: values[2],
    [BreakPoint.xl]: values[3],
  });
};

// Converts points, designed for a specific breakpoint, into scaling values (vw's)

export const scaledValue = (value: number, breakPoint: BreakPoint): string => {
  const base = rawBreakPoints[breakPoint];
  return `${(value / base) * 100}vw`;
};

// Combines `rValue()` with `scaledValue()`'s

export const srValue = (property: string, values: [number, number, number, number]): string => {
  return rValue(property, [
    scaledValue(values[0], BreakPoint.s),
    scaledValue(values[1], BreakPoint.m),
    scaledValue(values[2], BreakPoint.l),
    scaledValue(values[3], BreakPoint.xl),
  ]);
};

// Converts HorizontalAlignment to flexbox

export const horizontalAlignmentToFlexbox = (position: HorizontalAlignment): string =>
  ({
    [HorizontalAlignment.left]: "flex-start",
    [HorizontalAlignment.center]: "center",
    [HorizontalAlignment.right]: "flex-end",
  }[position]);

// Converts VerticalAlignment to flexbox

export const verticalAlignmentToFlexbox = (position: VerticalAlignment): string =>
  ({
    [VerticalAlignment.top]: "flex-start",
    [VerticalAlignment.center]: "center",
    [VerticalAlignment.bottom]: "flex-end",
  }[position]);
