import React from "react";
import { IArticleTableContent } from "../../../domain";
import * as Styled from "./TableContent.styled";

interface IProps {
  content: IArticleTableContent;
}

export default function TableContent(props: IProps) {
  return (
    <Styled.ContainerDiv>
      <table>
        <tbody>
          {props.content.table.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((column, columnIndex) => (
                <Styled.TableCell key={columnIndex}>{column}</Styled.TableCell>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Styled.ContainerDiv>
  );
}
