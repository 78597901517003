import React from "react";
import styled from "styled-components";
import { IArticleChartContent } from "../../../domain";
import Chart from "../../shared/Chart";

interface IProps {
  content: IArticleChartContent;
}

const ContainerDiv = styled.div``;

export default function ChartContent(props: IProps) {
  return (
    <ContainerDiv>
      <Chart chart={props.content.chart} />
    </ContainerDiv>
  );
}
