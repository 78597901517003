import styled from "styled-components";

export const TeaserLink = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-decoration: none;
  width: 100%;
  height: 100%;
  color: inherit;
  cursor: pointer;
`;
