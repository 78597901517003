import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";
import LanguageContext from "../context/LanguageContext";
import { Language, DefaultLanguage } from "../domain";
import ArticlePage from "./ArticlePage";
import OverviewPage from "./OverviewPage";
import { rawBreakPoints, BreakPoint } from "../layout";
import MobileView from "./MobileView";
import IEView from "./IEView";
import { isEn } from "../routing";

smoothscroll.polyfill();

export default function App() {
  const [language, setLanguage] = useState<Language>(isEn(window.location.hostname) ? Language.en : DefaultLanguage);
  const [innerWidth, setInnerWidth] = useState<number>(window.innerWidth);
  const isMobile = innerWidth < rawBreakPoints[BreakPoint.s];
  var ua = window.navigator.userAgent;
  const isIE = ua.indexOf('MSIE ') > 0 || ua.indexOf('Trident/') > 0;

  const handleResize = () => {
    setInnerWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {isMobile && <MobileView />}
      {!isMobile && isIE && <IEView />}
      {!isMobile && !isIE && (
        <Router>
          <Switch>
            <Route path="/:pageId?/:articleId?">
              <ArticlePage />
              <OverviewPage />
            </Route>
          </Switch>
        </Router>
      )}
    </LanguageContext.Provider>
  );
}
