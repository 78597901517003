import React from "react";
import styled from "styled-components";
import { BreakPoint, rValue, scaledValue, srValue } from "../../../layout";
import { contentColumnWidths, sidebarColumnWidths, inbetweenColumnWidths } from "../utils";

interface IProps {
  children: JSX.Element | JSX.Element[];
}

const ContainerDiv = styled.div`
  position: relative;
  ${srValue("width", sidebarColumnWidths)}
`;

const InnerContentDiv = styled.div`
  position: absolute;
  width: 100%;
  ${rValue("transform", [
    `translateX(${scaledValue(contentColumnWidths[0] + inbetweenColumnWidths[0], BreakPoint.s)})`,
    `translateX(${scaledValue(contentColumnWidths[1] + inbetweenColumnWidths[1], BreakPoint.m)})`,
    `translateX(${scaledValue(contentColumnWidths[2] + inbetweenColumnWidths[2], BreakPoint.l)})`,
    `translateX(${scaledValue(contentColumnWidths[3] + inbetweenColumnWidths[3], BreakPoint.xl)})`,
  ])};
`;

export default function SidebarContent(props: IProps) {
  return (
    <ContainerDiv>
      <InnerContentDiv>{props.children}</InnerContentDiv>
    </ContainerDiv>
  );
}
