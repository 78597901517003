import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'array-flat-polyfill';

import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import "./global-styles/fonts.css";
import "./global-styles/hacks.css";
import "./global-styles/reset.css";
import "./global-styles/delighters.css";
import "./global-styles/orientation.css";
import "./global-styles/animations.css";
import "./global-styles/common.css";
import "./global-scripts/firebase.ts";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
