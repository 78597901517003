import * as React from "react";
import {
  ContainerDiv,
  StyledYouTube,
  BodyText,
  LabelText,
  TextContainerDiv,
  TitleText,
} from "./ThreeSixyTeaser.styled";
import { IThreeSixyTeaser } from "../../../domain";
import { IContentInset } from "../../../layout";
import { Options } from "react-youtube";

interface IProps {
  teaser: IThreeSixyTeaser;
  contentInset: IContentInset;
  sectionColor: string;
  shouldBeVisible: boolean;
}

export default function ThreeSixyTeaser(props: IProps) {
  const { teaser, contentInset, sectionColor, shouldBeVisible } = props;

  const opts: Options = {
    height: `${window.innerHeight}`,
    width: `${window.innerWidth}`,
    playerVars: {
      loop: 0, // turn this to 1 if Persgroep wants the video to be looped
      controls: 0,
      playlist: "uAP8nHFY-Ss",
      modestbranding: 1,
    },
  };

  const onEnd = (e: any) => e.target.stopVideo(); // and remove this function if persgroep wants to loop the video

  return (
    <ContainerDiv teaser={teaser} className="image-container">
      {shouldBeVisible && <StyledYouTube videoId="uAP8nHFY-Ss" opts={opts} onEnd={onEnd} />}
      {teaser.title && (
        <TextContainerDiv teaser={teaser} contentInset={contentInset} className="textcontainer">
          {teaser.label && <LabelText teaser={teaser} text={teaser.label} />}
          {teaser.title && (
            <TitleText isTeaser={true} teaser={teaser} text={teaser.title} sectionColor={sectionColor} />
          )}
          {teaser.body && <BodyText teaser={teaser} text={teaser.body} />}
        </TextContainerDiv>
      )}
    </ContainerDiv>
  );
}
