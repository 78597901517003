import styled from "styled-components";

export const ContainerDiv = styled.div`
  position: relative;
  width: 100%;
  padding-top: ${(p: { aspectRatio: number }) => `${p.aspectRatio * 100}%`};
`;

export const Frame = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  border: none;
`;
