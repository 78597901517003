import React, { useContext } from "react";
import LanguageContext from "../../context/LanguageContext";
import { IPlainText } from "../../domain";

interface IProps {
  text: IPlainText;
  style?: React.CSSProperties;
  className?: string;
}

export default function PlainText(props: IProps) {
  const { language } = useContext(LanguageContext);

  return (
    <p className={props.className} style={props.style}>
      {props.text[language]}
    </p>
  );
}
