import { Language } from "./domain";

export const Urls = {
  prod: {
    [Language.nl]: "jaarverslag2019.dpgmediagroup.com",
    [Language.en]: "annual-report2019.dpgmediagroup.com",
  },
  acc: {
    [Language.nl]: "dpg-jaarverslag-acc.web.app",
    [Language.en]: "dpg-jaarverslag-acc.firebaseapp.com",
  },
}

export function isEn(hostname: string): boolean {
  const enUrls = [
    Urls.prod[Language.en],
    Urls.acc[Language.en],
  ];
  return enUrls.includes(hostname);
}

export function isProd(hostname: string): boolean {
  const prodUrls = [
    Urls.prod[Language.en],
    Urls.prod[Language.nl],
  ].filter(u => !!u);
  return prodUrls.includes(hostname);
}

export function isAcc(hostname: string): boolean {
  const accUrls = [
    Urls.acc[Language.en],
    Urls.acc[Language.nl],
  ];
  return accUrls.includes(hostname);
}
