import React from "react";
import { ITeaser } from "../../../domain";
import Teaser from "../Teaser";
import { ContainerDiv, IContainerDivProps, TeaserDiv } from "./Column.styled";
import { IContentInset, TopInset } from "../../../layout";

interface IProps {
  size: IContainerDivProps["size"];
  align: IContainerDivProps["align"];
  contentInset: IContentInset;
  teasers: ITeaser[];
  sectionColor: string;
  shouldBeVisible: boolean;
}

export function Column(props: IProps) {
  return (
    <ContainerDiv size={props.size} align={props.align}>
      {props.teasers.map((teaser, index) => (
        <TeaserDiv key={index} flexGrow={props.align === "stretch" ? 1 : 0}>
          <Teaser
            teaser={teaser}
            sectionColor={props.sectionColor}
            shouldBeVisible={props.shouldBeVisible}
            // The top-inset is only relevant for the first teaser in a column:
            contentInset={index === 0 ? props.contentInset : { ...props.contentInset, top: TopInset.default }}
            isLastInColumn={index === props.teasers.length - 1}
          />
        </TeaserDiv>
      ))}
    </ContainerDiv>
  );
}
