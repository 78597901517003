import styled from "styled-components";
import Color from "../../colors";

export const ContainerDiv = styled.div`
  position: fixed;
  opacity: ${(p: { isVisible: boolean }) => (p.isVisible ? 1 : 0)};
  transition: opacity 0.2s ease-out;
  height: 10px;
  width: 100%;
  z-index: 999;
  background: ${Color.DarkGray};
`;

interface IProgressBarDivProps {
  widthPercentage: number;
  color: string;
}

export const ProgressBarContainerDiv = styled.div`
  height: 100%;
  margin-left: 25vw;
`;

export const ProgressBarDiv = styled.div`
  height: 100%;
  width: ${(props: IProgressBarDivProps) => props.widthPercentage}%;
  background-color: ${(props: IProgressBarDivProps) => props.color};
  transition: width 0.5s ease-in-out;
`;

export const SectionHeaderPlaceholder = styled.div`
  position: absolute;
  height: 100%;
  width: 25vw;
  background-color: ${(props: { color: string }) => props.color};
`;
