import React, { useContext } from "react";
import { IArticleHeader, IColor } from "../../domain";
import * as Styled from "./Header.styled";
import { urls } from "../../data/overview";
import LanguageContext from "../../context/LanguageContext";
// import { StyledYouTube } from "../OverviewPage/Teaser/ThreeSixyTeaser.styled";
// import { Options } from "react-youtube";

interface IProps {
  onCloseArticle: () => void;
  header: IArticleHeader;
  sectionColor: IColor;
  pageId: string;
}

function makeSectionNameFrom(pageId: string, language: "nl" | "en") {
  const sectionId = pageId.substr(0, pageId.lastIndexOf("-"));
  const section = urls.find(s => s.title[language].replace(/[^a-zA-Z]+/g, '-') === sectionId);
  if (section)
    return section.title[language];
  
  const otherLang = language === "en" ? "nl" : "en";
  const sectionOtherLang = urls.find(s => s.title[otherLang].replace(/[^a-zA-Z]+/g, '-') === sectionId);
  if (sectionOtherLang)
    return sectionOtherLang.title[language];
  
  return "TODO " + sectionId;
}

export default function Header(props: IProps) {
  const { header, sectionColor, onCloseArticle, pageId } = props;
  const { language } = useContext(LanguageContext);
  const sectionName = makeSectionNameFrom(pageId, language);

  // const opts: Options = {
  //   playerVars: {
  //     loop: 1, // turn this to 1 if Persgroep wants the video to be looped
  //     controls: 0,
  //     playlist: "uAP8nHFY-Ss",
  //     modestbranding: 1,
  //     autoplay: 1,
  //     showinfo: 0,
  //   },
  // };

  // //const onEnd = (e: any) => e.target.stopVideo(); // and remove this function if persgroep wants to loop the video
  // const onEnd = (e: any) => { };

  if (header.isArticleView2) {
    return (
      <Styled.ContainerDiv>
        {header.image && (
          <Styled.WideImageColumnDiv>
            <Styled.BackToPageLink onClick={onCloseArticle}>
              <Styled.ArrowLeft />
              {language === "en" ? "Annual report" : "Jaarverslag"} / {sectionName}
            </Styled.BackToPageLink>
            {header.image?.alt?.startsWith('youtube:') && (
              <iframe title="news-city" src="https://micr.io/i/mYcIO/nl/dpg-media-newscity/" width="600" height="400" allow="autoplay" id="micrio-frame"/>
              // <StyledYouTube videoId={header.image.alt.substr(8)} opts={opts} onEnd={onEnd} />
            )}
            <Styled.ContentImageDiv header={header}>
              {header.label && (
                <Styled.ImageLabelStyledText
                  text={header.label}
                  color={header.labelColor}
                  font={header.labelFont}
                  size={header.labelSize}
                />
              )}
              {header.title && (
                <Styled.ImageTitleStyledText
                  text={header.title}
                  color={header.titleColor}
                  boldWordColor={header.boldWordColor || sectionColor}
                />
              )}
            </Styled.ContentImageDiv>
          </Styled.WideImageColumnDiv>
        )}
      </Styled.ContainerDiv>
    );
  }

  return (
    <Styled.ContainerDiv>
      <Styled.BackToPageLink onClick={onCloseArticle}>
        <Styled.ArrowLeft />
        {language === "en" ? "Annual report" : "Jaarverslag"} / {sectionName}
      </Styled.BackToPageLink>

      {header.image?.src && (
        <Styled.ImageColumnDiv>
          <Styled.ImageDiv header={header} />
        </Styled.ImageColumnDiv>
      )}

      {header.image?.src && (
      <Styled.ContentColumnDiv>
        <Styled.ContentDiv>
          {header.label && (
            <Styled.LabelStyledText text={header.label} font={header.labelFont} size={header.labelSize} />
          )}
          {header.title && <Styled.TitleStyledText text={header.title} boldWordColor={sectionColor} />}
          {header.intro && <Styled.IntroStyledText text={header.intro} />}
        </Styled.ContentDiv>
      </Styled.ContentColumnDiv>
      )}
    </Styled.ContainerDiv>
  );
}
