import styled from "styled-components";
import { srValue } from "../../../layout";
import { twoColumnsWidths } from "../utils";

export const ContainerDiv = styled.div``;

export const Image = styled.img`
&.double {
  ${srValue("width", twoColumnsWidths)}
  ${srValue("max-width", twoColumnsWidths)}
}
`;
