// General

export enum Language {
  nl = "nl",
  en = "en",
}

export const DefaultLanguage: Language = Language.nl;

export enum FontFamily {
  sharpSansDisplay = "sharp_sans_display",
  freightTextPro = "freight-text-pro",
}

export type IColor = string;

export interface IPlainText {
  nl: string;
  en: string;
}

export interface IStyledText {
  nl: ILocalizedStyledText;
  en: ILocalizedStyledText;
}

export enum StyledTextType {
  Heading1 = "heading1",
  Heading2 = "heading2",
  Heading3 = "heading3",
  Heading4 = "heading4",
  Heading5 = "heading5",
  Heading6 = "heading6",
  Paragraph = "paragraph",
  Preformatted = "preformatted",
}

export interface ILocalizedStyledText {
  type: StyledTextType;
  text: string;
  fontFamily: FontFamily;
  spans: IStyledTextSpan[];
}

export enum StyledTextSpanType {
  text,
  strong,
  em,
  break,
}

export interface IStyledTextSpan {
  start: number;
  end: number;
  type: StyledTextSpanType;
}

export interface IImage {
  src: string;
  alt: string;
}

export enum VerticalAlignment {
  top = "top",
  center = "center",
  bottom = "bottom",
}

export enum HorizontalAlignment {
  left = "left",
  center = "center",
  right = "right",
}

export interface IChart {
  // TODO: Define different type of charts somehow?
  values: IChartValue[];
}

export interface IChartValue {
  label: string;
  value: number;
}

// Routing

export interface IRouteParams {
  pageId?: string;
  articleId?: string;
}

// Overview

export interface IOverview {
  sections: IOverviewSection[];
}

export type SectionTitleCms =
  "Opening" |
  "Voorwoord" |
  "Voorpagina" |
  "Nieuws" |
  "Entertainment" |
  "Online Services" |
  "B2B" |
  "Advertising" |
  "Financieel";
export type SectionId =
  "opening" |
  "voorwoord" |
  "voorpagina" |
  "nieuws" |
  "entertainment" |
  "onlineservices" |
  "b2b" |
  "advertising" |
  "financieel";

export interface IOverviewSection {
  _id: SectionId;
  title: IPlainText;
  navigation: IPlainText;
  color: IColor;
  pages: IOverviewPage[];
}

export interface IOverviewPage {
  _id: string;
  grid: IGrid;
}

export enum GridLayout {
  A = "A", // 100%
  B = "B", // 25 % / 50% / 25%
  C = "C", // 75% / 25%
  D = "D", // 25% / 75%
  Cover = "Cover",
}

export interface IGrid {
  layout: GridLayout;
  teasers: ITeaser[][];
}

export type ITeaser = IBasicTeaser | IImageTeaser | ITitleTeaser | IThreeSixyTeaser;

interface IBaseTeaser {
  articleId?: string;
}

export interface IBasicTeaser extends IBaseTeaser {
  _type: "basic";
  title?: IStyledText;
  body?: IStyledText;
  image?: IImage;
  image_en?: IImage;
  imagePosition: VerticalAlignment;
  fontFamily?: FontFamily;
}

export interface IImageTeaser extends IBaseTeaser {
  _type: "image";
  labelFont?: FontFamily;
  labelSize?: "small" | "large";
  label?: IStyledText;
  title?: IStyledText;
  body?: IStyledText;
  image?: IImage;
  image_en?: IImage;
  horizontalImagePosition: HorizontalAlignment;
  verticalImagePosition: VerticalAlignment;
  textColor: IColor;
  boldWordColor?: string;
  textWidth: number;
  textAlignment: HorizontalAlignment;
  verticalTextPosition: VerticalAlignment;
  horizontalTextPosition: HorizontalAlignment;
  gradientOpacity: number;
  gradientColor: IColor;
}

export interface IThreeSixyTeaser extends IBaseTeaser {
  _type: "360";
  labelFont?: FontFamily;
  labelSize?: "small" | "large";
  label?: IStyledText;
  title?: IStyledText;
  body?: IStyledText;
  image?: IImage;
  horizontalImagePosition: HorizontalAlignment;
  verticalImagePosition: VerticalAlignment;
  textColor: IColor;
  boldWordColor?: string;
  textWidth: number;
  textAlignment: HorizontalAlignment;
  verticalTextPosition: VerticalAlignment;
  horizontalTextPosition: HorizontalAlignment;
  gradientOpacity: number;
  gradientColor: IColor;
}

export interface ITitleTeaser extends IBaseTeaser {
  _type: "title";
  title?: IStyledText;
}

// Article

export interface IArticle {
  header: IArticleHeader;
  content: {
    nl: IArticleContent[];
    en: IArticleContent[];
  };
}

export interface IArticleHeader {
  isArticleView2?: boolean;
  labelFont?: FontFamily;
  labelSize?: "small" | "large";
  labelColor?: string;
  label?: IStyledText;
  titleColor?: string;
  boldWordColor?: string;
  gradient?: boolean;
  title?: IStyledText;
  intro?: IStyledText;
  image?: IImage;
  horizontalImagePosition: HorizontalAlignment;
  verticalImagePosition: VerticalAlignment;
}

export type IArticleContent =
  | IArticleTextContent
  | IArticleImageContent
  | IArticleTableContent
  | IArticleEmbedContent
  | IArticleChartContent
  | IArticleSidebarQuoteContent
  | IArticleSidebarStatisticContent
  | IArticleSidebarImageContent
  | IArticleHR
  | IArticleContentHeader;

export interface IArticleContentHeader {
  _type: "intro";
  text: ILocalizedStyledText;
}

export interface IArticleHR {
  _type: "hr";
  width: "single" | "double";
}

export interface IArticleTextContent {
  _type: "text";
  text: ILocalizedStyledText;
}

export interface IArticleImageContent {
  _type: "image";
  image: IImage;
  delighter?: any;
}

export interface IArticleTableContent {
  _type: "table";
  table: string[][];
}

export interface IArticleEmbedContent {
  _type: "embed";
  url: string;
  width?: number;
  height?: number;
}

export interface IArticleChartContent {
  _type: "chart";
  chart: IChart;
}

export interface IArticleSidebarQuoteContent {
  _type: "sidebar-quote";
  quote: ILocalizedStyledText;
}

export interface IArticleSidebarStatisticContent {
  _type: "sidebar-statistic";
  number: ILocalizedStyledText; // Large text, usually a percentage
  text: ILocalizedStyledText; // Smaller text, describing the number
}

export interface IArticleSidebarImageContent {
  _type: "sidebar-image";
  image: IImage;
  delighter?: any;
}
