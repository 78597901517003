enum Color {
  Purple = "#783c96",
  Red = "#e6463c",
  Pink = "#d23278",
  Yellow = "#fabb22",
  FinancialBlue = "#335979",
  Blue = "#4cafd3",
  Grey = "#e6e6e6",
  DarkGray = "#828282",
  Black = "#000000",
  White = "#FFFFFF",
  LightGray = "#d8d8d8",
  DoubleDevil = "#666666",
}

export default Color;
