import React from "react";
import styled from "styled-components";
import { IArticleSidebarQuoteContent, StyledTextType, FontFamily } from "../../../domain";
import { rValue } from "../../../layout";
import LocalizedStyledText from "../../shared/LocalizedStyledText";
import SidebarContent from "./SidebarContent";

interface IProps {
  content: IArticleSidebarQuoteContent;
}

const COLOR = "#323232";
const QuoteLocalizedStyledText = styled(LocalizedStyledText)`
  font-style: italic;
  line-height: 1.25;
  color: ${COLOR};
  ${rValue("font-size", ["28px", "32px", "36px", "40px"])};
  ${rValue("margin-bottom", ["16px", "16px", "20px", "20px"])};
`;

const QuoteAuthorLocalizedStyledText = styled(LocalizedStyledText)`
  font-weight: bold;
  color: ${COLOR};
  ${rValue("line-leight", ["1.29", "1.25", "1.25", "1.33"])};
  ${rValue("font-size", ["14px", "16px", "16px", "18px"])};
`;

const QuoteTitleLocalizedStyledText = styled(LocalizedStyledText)`
  color: ${COLOR};
  ${rValue("font-size", ["36px", "36px", "36px", "48px"])};
  ${rValue("font-size", ["14px", "16px", "16px", "18px"])};
`;

export default function SidebarQuoteContent(props: IProps) {
  const { text } = props.content.quote;
  const [q, a] = text.split(`<eind-quote>`);
  const quoteText = q.substring(1, q.length - 1);
  const [authorText, titleText] = a ? a.split(", ") : ["",""];

  const quote = {
    ...props.content.quote,
    text: `“${quoteText}”`,
  };

  const author = {
    type: StyledTextType.Paragraph,
    text: authorText,
    fontFamily: FontFamily.sharpSansDisplay,
    spans: [],
  };

  const title = {
    type: StyledTextType.Paragraph,
    text: titleText,
    fontFamily: FontFamily.sharpSansDisplay,
    spans: [],
  };

  return (
    <SidebarContent>
      <QuoteLocalizedStyledText text={quote} />
      {author && <QuoteAuthorLocalizedStyledText text={author} />}
      {title && <QuoteTitleLocalizedStyledText text={title} />}
    </SidebarContent>
  );
}
