import { css } from "styled-components";
import {
  BreakPoint,
  IContentInset,
  IResponsiveValue,
  LeftInset,
  responsiveValue,
  RightInset,
  TopInset,
} from "../../layout";

export const headerHeight: IResponsiveValue = {
  [BreakPoint.s]: "60px",
  [BreakPoint.m]: "60px",
  [BreakPoint.l]: "60px",
  [BreakPoint.xl]: "80px",
};

export const navigationHeight: IResponsiveValue = {
  [BreakPoint.s]: "50px",
  [BreakPoint.m]: "50px",
  [BreakPoint.l]: "50px",
  [BreakPoint.xl]: "66px",
};

export const pageGutter: IResponsiveValue = {
  [BreakPoint.s]: "40px",
  [BreakPoint.m]: "40px",
  [BreakPoint.l]: "80px",
  [BreakPoint.xl]: "120px",
};

export const horizontalGutter: IResponsiveValue = {
  [BreakPoint.s]: "40px",
  [BreakPoint.m]: "40px",
  [BreakPoint.l]: "40px",
  [BreakPoint.xl]: "40px",
};

export const topGutter: IResponsiveValue = {
  [BreakPoint.s]: "30px",
  [BreakPoint.m]: "30px",
  [BreakPoint.l]: "40px",
  [BreakPoint.xl]: "42px",
};

export const bottomGutter: IResponsiveValue = {
  [BreakPoint.s]: "20px",
  [BreakPoint.m]: "20px",
  [BreakPoint.l]: "30px",
  [BreakPoint.xl]: "32px",
};

export const teaserInset = css`
  ${(p: { contentInset: IContentInset }) => {
    const getInset = (breakPoint: BreakPoint) =>
      ({
        [TopInset.default]: topGutter[breakPoint],
        [TopInset.header]: `calc(${headerHeight[breakPoint]} + ${topGutter[breakPoint]})`,
      }[p.contentInset.top]);

    return responsiveValue("padding-top", {
      [BreakPoint.s]: getInset(BreakPoint.s),
      [BreakPoint.m]: getInset(BreakPoint.m),
      [BreakPoint.l]: getInset(BreakPoint.l),
      [BreakPoint.xl]: getInset(BreakPoint.xl),
    });
  }}

  ${(p: { contentInset: IContentInset }) => {
    const getInset = (breakPoint: BreakPoint) =>
      ({
        [RightInset.default]: horizontalGutter[breakPoint],
        [RightInset.page]: pageGutter[breakPoint],
      }[p.contentInset.right]);

    return responsiveValue("padding-right", {
      [BreakPoint.s]: getInset(BreakPoint.s),
      [BreakPoint.m]: getInset(BreakPoint.m),
      [BreakPoint.l]: getInset(BreakPoint.l),
      [BreakPoint.xl]: getInset(BreakPoint.xl),
    });
  }}

  ${(p: { contentInset: IContentInset }) => {
    const getInset = (breakPoint: BreakPoint) =>
      ({
        [LeftInset.default]: horizontalGutter[breakPoint],
        [LeftInset.page]: pageGutter[breakPoint],
      }[p.contentInset.left]);

    return responsiveValue("padding-left", {
      [BreakPoint.s]: getInset(BreakPoint.s),
      [BreakPoint.m]: getInset(BreakPoint.m),
      [BreakPoint.l]: getInset(BreakPoint.l),
      [BreakPoint.xl]: getInset(BreakPoint.xl),
    });
  }}

  ${responsiveValue("padding-bottom", bottomGutter)}
`;
