import React from "react";
import { useParams } from "react-router";
import Panel from "./Panel";

export default function ArticlePage() {
  const { pageId, articleId } = useParams();
  if (pageId && articleId) {
    return <Panel pageId={pageId} articleId={articleId} />;
  } else {
    return null;
  }
}
