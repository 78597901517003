import React, { useContext } from "react";
import LanguageContext from "../../../context/LanguageContext";
import { Language } from "../../../domain";
import { IBasicTeaser, VerticalAlignment } from "../../../domain";
import { IContentInset } from "../../../layout";
import { BodyStyledText, ContainerDiv, ImageImg, TitleStyledText } from "./BasicTeaser.styled";

interface IProps {
  teaser: IBasicTeaser;
  contentInset: IContentInset;
  sectionColor: string;
  shouldBeVisible: boolean;
}

export default function BasicTeaser(props: IProps) {
  const { language } = useContext(LanguageContext);
  const { teaser, sectionColor, shouldBeVisible } = props;
  const imgSrc = shouldBeVisible ? (language === Language.en && teaser.image_en?.src? teaser.image_en?.src : teaser.image?.src) : "";

  return (
    <ContainerDiv contentInset={props.contentInset}>
      {teaser.image && teaser.imagePosition === VerticalAlignment.top && (
        <ImageImg src={imgSrc} alt={teaser.image.alt} />
      )}

      {teaser.title && (
        <TitleStyledText
          fontFamily={teaser.fontFamily}
          sectionColor={sectionColor}
          isTeaser={true}
          text={teaser.title}
        />
      )}

      {teaser.body && <BodyStyledText sectionColor={sectionColor} isTeaser={true} text={teaser.body} />}
      {teaser.image && teaser.imagePosition === VerticalAlignment.bottom && (
        <ImageImg src={imgSrc} alt={teaser.image.alt} />
      )}
    </ContainerDiv>
  );
}
