export enum ArticleState {
  Shown,
  Hidden,
  AnimatedShow,
  AnimatedHide,
}

export const getArticleState = (historyAction: string, articleId?: string) => {
  if (historyAction === "POP") {
    if (articleId) {
      return ArticleState.Shown;
    } else {
      return ArticleState.Hidden;
    }
  } else {
    if (articleId) {
      return ArticleState.AnimatedShow;
    } else {
      return ArticleState.AnimatedHide;
    }
  }
};

export const contentColumnWidths: [number, number, number, number] = [323, 452, 479, 677];
export const inbetweenColumnWidths: [number, number, number, number] = [40, 122, 143, 183];
export const sidebarColumnWidths: [number, number, number, number] = [233, 288, 376, 533];
export const twoColumnsWidths: [number, number, number, number] = [
  contentColumnWidths[0] + inbetweenColumnWidths[0] + sidebarColumnWidths[0],
  contentColumnWidths[1] + inbetweenColumnWidths[1] + sidebarColumnWidths[1],
  contentColumnWidths[2] + inbetweenColumnWidths[2] + sidebarColumnWidths[2],
  contentColumnWidths[3] + inbetweenColumnWidths[3] + sidebarColumnWidths[3],
];
