import styled from "styled-components";
import { FontFamily } from "../domain";
import Color from "../colors";

export const ContainerDiv = styled.div`
  display: flex;
  align-items: baseline;
`;

export const LanguageButton = styled.button`
  flex: 1;
  font-family: ${FontFamily.sharpSansDisplay};
  font-weight: ${(props: { isActive: boolean, textColor: Color }) => (props.isActive ? "bold" : "normal")};
  font-size: 16px;
  line-height: 100%;
  color: ${(props: { isActive: boolean, textColor: Color }) => props.textColor};
  text-decoration: none;
  padding: 8px;
  cursor: pointer;
  position: relative;
`;

export const SeparatorDiv = styled.div`
  flex: 1;
  width: 1px;
  height: 14px;
  background: ${(props: { textColor: Color }) => props.textColor};
`;
