import styled from "styled-components";
import { responsiveValue } from "../../../layout";
import StyledText from "../../shared/StyledText";
import { teaserInset, bottomGutter } from "../layout";
import Color from "../../../colors";
import { IProps as IStyledTextProps } from "../../shared/StyledText";

export const ContainerDiv = styled.div`
  ${teaserInset}
`;

export const ImageImg = styled.img`
  width: 100%;
  ${responsiveValue("margin-bottom", bottomGutter)};
`;

export const TitleStyledText = styled(StyledText)`
  line-height: 1.17;
  color: ${Color.Black};
  font-family: ${(props: IStyledTextProps) => props.fontFamily};
  ${responsiveValue("margin-bottom", bottomGutter)};

  strong {
    color: ${(props: IStyledTextProps) => props.sectionColor};
  }
`;

export const BodyStyledText = styled(StyledText)`
  line-height: 1.33;
  color: ${Color.Black};
  ${responsiveValue("margin-bottom", bottomGutter)};
`;
