import firebase from 'firebase/app';
import 'firebase/analytics';

export let analytics: { 
  setCurrentScreen: (screen: string, options?: any) => void,
  logEvent: (event: string, params?: any, options?: any) => void,
};

switch (window.location.hostname) {
  case "annual-report2019.dpgmediagroup.com":
  case "jaarverslag2019.dpgmediagroup.com":
  case "dpg-jaarverslag.web.app":
  case "dpg-jaarverslag.firebaseapp.com":
    firebase.initializeApp({
      apiKey: "AIzaSyAzv3CEcgQA7cmzd0NV2S8Tyyua16qYj5c",
      authDomain: "dpg-jaarverslag.firebaseapp.com",
      databaseURL: "https://dpg-jaarverslag.firebaseio.com",
      projectId: "dpg-jaarverslag",
      storageBucket: "dpg-jaarverslag.appspot.com",
      messagingSenderId: "18681186331",
      appId: "1:18681186331:web:d048d3fe492a6d647c90be",
      measurementId: "G-3SLVKDK4TC"
    });
    analytics = firebase.analytics();
    break;
  case "dpg-jaarverslag-acc.web.app":
  case "dpg-jaarverslag-acc.firebaseapp.com":
      firebase.initializeApp({
      apiKey: "AIzaSyDzDajlLm1Z6e2B2BrU3C0HbVHysK6oKv8",
      authDomain: "dpg-jaarverslag-acc.firebaseapp.com",
      databaseURL: "https://dpg-jaarverslag-acc.firebaseio.com",
      projectId: "dpg-jaarverslag-acc",
      storageBucket: "dpg-jaarverslag-acc.appspot.com",
      messagingSenderId: "155392680336",
      appId: "1:155392680336:web:4b693e67ac069cc2abd315",
      measurementId: "G-P74HJF7S1X"
    });
    analytics = firebase.analytics();
    break;
  default:
    console.log('Local environment, not tracking analytics');
    analytics = {
      setCurrentScreen(...args) { console.log(`setCurrentScreen(${args})`); },
      logEvent(...args) { console.log(`logEvent(${args})`); },
    }
}
