import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import { getArticle, getOverview } from "../../data";
import { IOverviewSection } from "../../domain";
import ErrorScreen from "../shared/ErrorScreen";
import LoadingScreen from "../shared/LoadingScreen";
import Content from "./Content";
import * as Styled from "./Panel.styled";
import ScrollIndicator from "./ScrollIndicator";
import { ArticleState, getArticleState } from "./utils";
import Delighters from "../../global-scripts/delighters.js";

interface IProps {
  pageId: string;
  articleId: string;
}

export default React.memo(({ pageId, articleId }: IProps) => {
  const history = useHistory();
  const articleData = useQuery(["article", { articleId }], getArticle);
  const overviewData = useQuery("overview", getOverview);
  const [articleState, setArticleState] = useState(getArticleState(history.action, articleId));
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [scrollIndicatorIsVisible, setScrollIndicatorIsVisible] = useState(false);

  const sectionContainsCurrentPage = (s: IOverviewSection) => s.pages.findIndex((p) => p._id === pageId) > -1;
  const currentSection = overviewData.data?.sections.find(sectionContainsCurrentPage);
  const sectionColor = currentSection?.color || "black";
  
  useEffect(() => {
    Delighters.init();
  }, []);

  useEffect(() => {
    document.body.id = "article-page";
  }, []);

  useEffect(() => {
    setArticleState(getArticleState(history.action, articleId));
    setTimeout(() => setScrollIndicatorIsVisible(true), 400);
  }, [articleId, history.action]);

  useEffect(() => {
    function handleResize() {
      if (articleState !== ArticleState.Shown) {
        setArticleState(ArticleState.Shown);
      }
      setWindowHeight(window.innerHeight);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  const onCloseArticle = () => {
    setArticleState(ArticleState.AnimatedHide);
    setScrollIndicatorIsVisible(false);

    setTimeout(() => {
      history.push(`/${pageId}`);
      contentRef?.current?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, 1000);
  };

  const renderContent = () => {
    if (articleData.isLoading || overviewData.isLoading) {
      return <LoadingScreen />;
    }

    if (articleData.error) {
      return <ErrorScreen error={articleData.error} />;
    }

    if (overviewData.error) {
      return <ErrorScreen error={overviewData.error} />;
    }

    if (!articleData.data) {
      return <ErrorScreen error={new Error("No article found")} />;
    }

    return (
      <Content
        onCloseArticle={onCloseArticle}
        article={articleData.data}
        articleState={articleState}
        sectionColor={sectionColor}
        pageId={pageId}
      />
    );
  };

  return (
    <>
      <ScrollIndicator color={sectionColor} isVisible={scrollIndicatorIsVisible} />
      <Styled.ContainerDiv articleState={articleState} windowHeight={windowHeight}>
        <Styled.SectionBlock color={sectionColor} />
        <Styled.ContentContainer id="article-content-container" ref={contentRef}>
          {renderContent()}
        </Styled.ContentContainer>
      </Styled.ContainerDiv>
    </>
  );
});
