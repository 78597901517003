import styled from "styled-components";

export const ContainerDiv = styled.div`
  margin-left: -1rem;
  width: 100%;
  overflow-x: auto;
`;

export const TableCell = styled.td`
  padding: 0.5rem 1rem;
  vertical-align: top;
`;
