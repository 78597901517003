import React from "react";
import { IArticleTextContent, IArticleContentHeader } from "../../../domain";
import LocalizedStyledText from "../../shared/LocalizedStyledText";
import * as Styled from "./TextContent.styled";

interface IProps {
  content: IArticleTextContent | IArticleContentHeader;
}

export default function TextContent(props: IProps) {
  return (
    <Styled.ContainerDiv>
      <LocalizedStyledText text={props.content.text} />
    </Styled.ContainerDiv>
  );
}
