import fetch from "cross-fetch";
import parseCsv from "csv-parse";
import Prismic from "prismic-javascript";
import { FontFamily, IArticle, IArticleContent, IArticleTableContent, StyledTextType, IChartValue } from "../domain";
import {
  IPrismicImage,
  IPrismicStyledTextSpan,
  IPrismicText,
  parseHorizontalAlignment,
  parseImage,
  parseStyledTextSpans,
  parseStyledTextType,
  parseText,
  parseVerticalAlignment,
} from "./utils";
import { isProd } from "../routing";

export async function getArticle(props: { articleId: string }) {
  const article = isProd(window.location.hostname) ? await fromLocalFile(props) : await fromPrismic(props);
  return article;
}

export async function fromPrismic({ articleId }: { articleId: string }): Promise<IArticle> {
  const api = await Prismic.getApi("https://pgjv2019.cdn.prismic.io/api/v2");
  const document = (await api.getByUID("article", articleId)) as IPrismicArticle;

  return {
    header: {
      isArticleView2: document.data.article_view_2,
      labelColor: document.data.label_color,
      titleColor: document.data.title_color,
      boldWordColor: document.data.bold_word_color,
      gradient: document.data.gradient,
      labelFont: document.data.font_family,
      labelSize: document.data.label_font_size,
      label: parseText(document.data.label_nl, document.data.label_en, FontFamily.freightTextPro),
      title: parseText(document.data.title_nl, document.data.title_en, FontFamily.sharpSansDisplay),
      intro: parseText(document.data.intro_nl, document.data.intro_en, FontFamily.freightTextPro),
      image: parseImage(document.data.header_image),
      horizontalImagePosition: parseHorizontalAlignment(document.data.header_image_horizontal_position),
      verticalImagePosition: parseVerticalAlignment(document.data.header_image_vertical_position),
    },
    content: {
      nl: await mapContentItems(document.data.content_nl),
      en: await mapContentItems(document.data.content_en),
    },
  };
}

export async function fromLocalFile({ articleId }: { articleId: string }): Promise<IArticle> {
  const response = await fetch(`/data/article-${articleId}.json`);
  return (await response.json()) as IArticle;
}

interface IPrismicArticle {
  data: {
    article_view_2: boolean;
    font_family: FontFamily;
    label_font_size: "small" | "large";
    label_color: string;
    label_nl: IPrismicText;
    label_en: IPrismicText;
    gradient: boolean;
    title_color: string;
    bold_word_color: string;
    title_nl: IPrismicText;
    title_en: IPrismicText;
    intro_nl: IPrismicText;
    intro_en: IPrismicText;
    header_image: IPrismicImage;
    header_image_horizontal_position: string;
    header_image_vertical_position: string;
    content_nl: IPrismicArticleContent[];
    content_en: IPrismicArticleContent[];
  };
}

interface IPrismicArticleImageContent {
  type: string;
  url?: string;
  alt?: string;
}

interface IPrismicArticleTextContent {
  type: string;
  text?: string;
  spans?: IPrismicStyledTextSpan[];
}

interface IPrismicArticleEmbedContent {
  type: string;
  oembed?: {
    html?: string;
    width?: number;
    height?: number;
  };
}

type IPrismicArticleContent = IPrismicArticleTextContent | IPrismicArticleImageContent | IPrismicArticleEmbedContent;

function isImageContent(content: IPrismicArticleContent): content is IPrismicArticleImageContent {
  return content.type === "image";
}

function isTextContent(content: IPrismicArticleContent): content is IPrismicArticleTextContent {
  return content.type !== "image" && content.type !== "embed";
}

function isEmbedContent(content: IPrismicArticleContent): content is IPrismicArticleEmbedContent {
  return content.type === "embed";
}

async function mapContentItems(contentItems: IPrismicArticleContent[]): Promise<IArticleContent[]> {
  const results: IArticleContent[] = [];
  let expectSidebarItem = false;
  let expectDelighter = false;
  let delighter:any = false;

  /* 
    expectSidebarItem and expectDelighter skip its own item but set a flag to mark the next item
  */
  for await (const item of contentItems) {
    const mappedItem = await mapContentItem(item, expectSidebarItem, expectDelighter, delighter);
    expectSidebarItem = isTextContent(item) && item.text?.trim().startsWith("sidebar:") === true;
    expectDelighter = isTextContent(item) && item.text?.trim().startsWith("delighter:") === true;
    if (expectSidebarItem) {
      delighter = isTextContent(item) && item.text?.replace(/sidebar:/gi, "");
    }
    if (expectDelighter) {
      delighter = isTextContent(item) && item.text?.replace(/delighter:/gi, "");
    }

    if (mappedItem && !expectSidebarItem && !expectDelighter) {
      results.push(mappedItem);
    }

    if (!expectSidebarItem && !expectDelighter) {
      delighter = false;
    }
  }

  return results;
}

async function mapContentItem(
  prismicValue: IPrismicArticleContent,
  expectSidebarItem: boolean,
  expectDelighter: boolean,
  delighter: any,
): Promise<IArticleContent | void> {
  //if (expectDelighter) return;

  if (expectSidebarItem) {
    if (isImageContent(prismicValue) && prismicValue.url) {
      return {
        _type: "sidebar-image",
        image: { src: prismicValue.url, alt: prismicValue.alt || "" },
        delighter: 'd-right'
      };
    }

    if (isTextContent(prismicValue) && (prismicValue.text || prismicValue.text === "")) {
      if (!prismicValue.text) {
        // don't display empty paragraphs
        //console.warn("Empty sidebar item!", prismicValue);
        return;
      }  

      // It starts with any kind of quote character, it must be a sidebar quote
      const quoteCharacters = [`"`, `“`, `”`, `’`, `‚`, `„`, `″`, `′`];
      if (quoteCharacters.includes(prismicValue.text.substring(0, 1))) {
        let { text } = prismicValue;

        return {
          _type: "sidebar-quote",
          quote: {
            type: StyledTextType.Heading1,
            fontFamily: FontFamily.freightTextPro,
            spans: [],
            text: `${text}`,
          },
        };
      }

      // It is delimited by a pipe, it must be a statistic
      const statisticParts = prismicValue.text.split("|");
      if (statisticParts.length === 2) {
        return {
          _type: "sidebar-statistic",
          number: {
            type: StyledTextType.Heading1,
            fontFamily: FontFamily.sharpSansDisplay,
            spans: [],
            text: statisticParts[0].trim(),
          },
          text: {
            type: StyledTextType.Heading2,
            fontFamily: FontFamily.freightTextPro,
            spans: [],
            text: statisticParts[1].trim(),
          },
        };
      }
    }

    console.warn("Expected sidebar item, but did not parse any", prismicValue);
    return;
  }

  if (isImageContent(prismicValue) && prismicValue.url) {
    return {
      _type: "image",
      image: { src: prismicValue.url, alt: prismicValue.alt || "" },
      delighter: 'fade'
    };
  }

  if (isEmbedContent(prismicValue) && prismicValue.oembed && prismicValue.oembed.html) {
    // Prismic's `embed_url` property contains the web-url, not the embed-url 🤦‍♂️
    // Get it from the iframe instead:
    const src = getSrcFromIframe(prismicValue.oembed.html);
    if (!src) {
      console.warn("Could not get src from iframe", prismicValue);
      return;
    }
    return { _type: "embed", url: src, width: prismicValue.oembed.width, height: prismicValue.oembed.height };
  }

  if (isTextContent(prismicValue) && (prismicValue.text || prismicValue.text === "")) {
    if (!prismicValue.text) {
      // don't display empty paragraphs
      return;
    }

    if (prismicValue.text.startsWith("table:")) {
      try {
        const [, url] = prismicValue.text.split("table:");
        const rows = await fetchTable(url.trim());
        return { _type: "table", table: rows };
      } catch (error) {
        console.warn("Could not create table from data", { prismicValue, error });
        return;
      }
    }

    if (prismicValue.text.startsWith("hr:")) {
      try {
        const [, width] = prismicValue.text.split("hr:");
        return { _type: "hr", width: width === "double" ? "double" : "single" };
      } catch (error) {
        console.warn("Could not create hr from data", { prismicValue, error });
        return;
      }
    }

    if (prismicValue.text.startsWith("chart:")) {
      try {
        const [, url] = prismicValue.text.split("chart:");
        const rows = await fetchTable(url.trim());
        const values: IChartValue[] = rows.map((row) => ({ label: row[0], value: parseFloat(row[1]) }));
        return { _type: "chart", chart: { values } };
      } catch (error) {
        console.warn("Could not create chart from data", { prismicValue, error });
        return;
      }
    }

    return {
      _type: "text",
      text: {
        type: parseStyledTextType(prismicValue.type),
        text: prismicValue.text,
        spans: parseStyledTextSpans(prismicValue.spans),
        fontFamily: FontFamily.freightTextPro,
      },
    };
  }

  console.warn("Expected to parse content, but did not do anything", prismicValue);
}

async function fetchTable(url: string): Promise<IArticleTableContent["table"]> {
  const response = await fetch(url);

  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`);
  }

  const body = await response.text();

  return new Promise((resolve, reject) => {
    parseCsv(body, (error, output) => {
      error ? reject(error) : resolve(output);
    });
  });
}

function getSrcFromIframe(html: string): string | undefined {
  const match = html.match(/(?:src=").*?(?=[?"])/);
  return match && match[0] ? match[0] : undefined;
}
