import styled, { keyframes } from "styled-components";
import { FontFamily } from "../../domain";
import { ArticleState, twoColumnsWidths } from "./utils";
import { srValue } from "../../layout";

interface IContainerDivProps {
  articleState: ArticleState;
  windowHeight: number;
}

const containerDivTransform = (props: IContainerDivProps) => {
  switch (props.articleState) {
    case ArticleState.Hidden:
    case ArticleState.AnimatedHide:
      return window.innerHeight;
    case ArticleState.Shown:
    case ArticleState.AnimatedShow:
      return 0;
  }
};

const containerDivKeyFrames = (props: IContainerDivProps) => {
  switch (props.articleState) {
    case ArticleState.Shown:
    case ArticleState.Hidden:
      return "";

    case ArticleState.AnimatedShow:
      return keyframes`
        from { transform: translateY(${props.windowHeight}px); }
        to { transform: translateY(0px); }
      `;

    case ArticleState.AnimatedHide:
      return keyframes`
        from { transform: translateY(0px); }
        to { transform: translateY(${props.windowHeight}px); }
      `;
  }
};

export const ContainerDiv = styled.div`
  position: fixed;
  transform: translateY(${containerDivTransform}px);
  animation: ${(props: IContainerDivProps) => containerDivKeyFrames(props)} 0.5s ease-in-out;
  background: white;
  font-family: ${FontFamily.freightTextPro};
  z-index: 999;
  height: 100vh;
  width: 100vw;
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 101;

  hr {
    border-top: 1px solid #D3D3D3;

    &.article-double {
      ${srValue("width", twoColumnsWidths)}
    }
  }
`;

interface ISectionBlockProps {
  color: string;
  articleState: ArticleState;
}

export const SectionBlock = styled.div`
  width: 20px;
  height: 140px;
  background-color: ${(props: { color: string }) => props.color};
  position: absolute;
  top: 0;
  right: 0;
  z-index: 102;
`;

// animation: ${(props: IContainerDivProps) => sectionBlockKeyFrames(props)} 0.7s ease-in-out;
export const ContentContainer = styled.div`
  overflow-y: scroll;
  background-color: white;
`;
