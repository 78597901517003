import React from "react";
import { IArticleImageContent } from "../../../domain";
import * as Styled from "./ImageContent.styled";

interface IProps {
  content: IArticleImageContent;
}

export default function ImageContent(props: IProps) {
  let alt = props.content.image.alt;
  let className = "";
  if (alt.indexOf("|") > -1) {
    const [options, text] = alt.split("|");
    if (options.indexOf("double") > -1) {
      className += "double";
    }
    alt = text;
  }

  if (props.content.delighter) {
    return (
      <Styled.ContainerDiv>
        <Styled.Image className={className} data-delighter={props.content.delighter} src={props.content.image.src} alt={alt} />
      </Styled.ContainerDiv>
    );  
  }
  return (
    <Styled.ContainerDiv>
      <Styled.Image className={className} src={props.content.image.src} alt={alt} />
    </Styled.ContainerDiv>
  );
}
