import ColorUtils from "color";
import styled from "styled-components";
import { IImageTeaser, Language } from "../../../domain";
import {
  horizontalAlignmentToFlexbox,
  IContentInset,
  rValue,
  verticalAlignmentToFlexbox,
} from "../../../layout";
import StyledText from "../../shared/StyledText";
import { teaserInset } from "../layout";
import { IProps as IStyledTextProps } from "../../shared/StyledText";

interface IContainerDivProps {
  teaser: IImageTeaser;
  lang: Language;
  shouldBeVisible: boolean;
}

export const ContainerDiv = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: ${(p: IContainerDivProps) => horizontalAlignmentToFlexbox(p.teaser.horizontalTextPosition)};
  justify-content: ${(p: IContainerDivProps) => verticalAlignmentToFlexbox(p.teaser.verticalTextPosition)};

  h2,
  p {
    width: 100%;
  }
`;

interface ITextContainerDivProps {
  teaser: IImageTeaser;
  contentInset: IContentInset;
}

export const VisualDiv = styled.div`
  position: absolute;
  top: 0; left: 0; width: 100%; height: 100%;
  overflow: hidden;
`;

export const InnerVisualDiv = styled.div`
  position: absolute;
  top: 0; left: 0; width: 100%; height: 100%;
  background-color: whitesmoke;
  background: ${(p: IContainerDivProps) => (p.shouldBeVisible ? `url(${p.lang === Language.en && p.teaser.image_en ?  p.teaser.image_en?.src : p.teaser.image?.src})` : "whitesmoke")};
  background-position-x: ${(p: IContainerDivProps) => p.teaser.horizontalImagePosition};
  background-position-y: ${(p: IContainerDivProps) => p.teaser.verticalImagePosition};
  background-size: cover;
  background-repeat: no-repeat;
  animation: visual 40s ease-in-out infinite;
`;

export const TextContainerDiv = styled.div`
  ${teaserInset};
  position: relative;
  z-index: 1;
  width: ${(p: ITextContainerDivProps) => `${p.teaser.textWidth * 100}%`};
  display: flex;
  flex-direction: column;
  align-items: ${(p: ITextContainerDivProps) => horizontalAlignmentToFlexbox(p.teaser.textAlignment)};
  color: ${(p: ITextContainerDivProps) => p.teaser.textColor};
`;

const Text = styled(StyledText)`
  ${rValue("margin-bottom", ["12px","12px","16px","20px"])}
  text-align: ${(p: { teaser: IImageTeaser }) => p.teaser.textAlignment};
  line-height: 120%;

  strong {
    color: ${(props: IStyledTextProps) => props.sectionColor};
  }
`;

export const LabelText = styled(Text)`
  ${(props: IStyledTextProps) => {
    const property = "font-size";
    return props.size === "small"
      ? rValue(property, ["16px", "18px", "18px", "22px"])
      : rValue(property, ["20px", "24px", "24px", "28px"]);
  }}
  font-family: ${(props: IStyledTextProps) => props.font} !important;
`;

export const TitleText = styled(Text)`
  strong {
    color: ${(props: IStyledTextProps) => props.sectionColor};
    line-height: 1rem;
  }
`;

export const BodyText = styled(Text)`
  ${rValue("font-size", ["18px", "18px", "24px", "30px"])}
`;

export const GradientDiv = styled.div`
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: ${(p: { teaser: IImageTeaser }) => {
    const from = ColorUtils(p.teaser.gradientColor).fade(1 - p.teaser.gradientOpacity);
    const to = ColorUtils(from).fade(1);
    return `linear-gradient(0deg, ${from} 0%, ${to} 100%)`;
  }};
`;
