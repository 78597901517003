import _ from "lodash";
import Prismic from "prismic-javascript";
import Color from "../colors";
import { FontFamily, IOverview, IOverviewPage, IOverviewSection, ITeaser, VerticalAlignment, SectionTitleCms, SectionId, Language, DefaultLanguage } from "../domain";
import {
  IPrismicImage,
  IPrismicText,
  parseColor,
  parseGridLayout,
  parseHorizontalAlignment,
  parseImage,
  parsePercentage,
  parseText,
  parseVerticalAlignment,
} from "./utils";
import { isProd, isEn } from "../routing";

export async function getOverview() {
  const article = isProd(window.location.hostname) ? await fromLocalFile() : await fromPrismic();
  return article;  
}

export async function fromPrismic(): Promise<IOverview> {
  const api = await Prismic.getApi("https://pgjv2019.cdn.prismic.io/api/v2");
  const response = await api.query(Prismic.Predicates.at("document.type", "page"), {
    // options from https://prismic.io/docs/javascript/query-the-api/query-options-reference
    fetchLinks: teaserFields,
    pageSize: 100,
  });
  const pages: IPrismicPage[] = response.results as any;
  const sections = createSections(pages, (typeof(window) !== "undefined" && isEn(window.location.hostname) ? Language.en : DefaultLanguage));
  return { sections };
}

export async function fromLocalFile(): Promise<IOverview> {
  const response = await fetch("/data/overview.json");
  return (await response.json()) as IOverview;
}

export function getTextColorByPageId(pageId: string): Color {
  const darkBackgrounds = [
    "Opening-1",
    "Voorwoord-1",
    "Nieuwsmedia-4", "Newsmedia-4",
    "Nieuwsmedia-7", "Newsmedia-7",
    "Entertainment-Media-1",
    "Entertainment-Media-4",
    "Entertainment-Media-6",
    "Online-Services-1",
    "Online-Services-4",
    "Advertising-Marketing-2",
  ];
  if (darkBackgrounds.indexOf(pageId) > -1) {
    return Color.White;
  }
  return Color.Black;
}

export const urls: IOverviewSection[] = [
  {
    _id: "opening",
    title: { nl: "Opening", en: "Opening" },
    navigation: { nl: "Opening", en: "Opening" },
    color: Color.Purple,
    pages: []
  },
  {
    _id: "voorwoord",
    title: { nl: "Voorwoord", en: "Preface" },
    navigation: { nl: "Voorwoord", en: "Preface" },
    color: Color.Pink,
    pages: []
  },
  {
    _id: "voorpagina",
    title: { nl: "Voorpagina", en: "Frontpage" },
    navigation: { nl: "Voorpagina", en: "Frontpage" },
    color: Color.Grey,
    pages: []
  },
  {
    _id: "nieuws",
    title: { nl: "Nieuwsmedia", en: "Newsmedia" },
    navigation: { nl: "Nieuwsmedia", en: "Newsmedia" },
    color: Color.Blue,
    pages: []
  },
  {
    _id: "entertainment",
    title: { nl: "Entertainment Media", en: "Entertainment Media" },
    navigation: { nl: "Entertainment", en: "Entertainment" },
    color: Color.Red,
    pages: []
  },
  {
    _id: "onlineservices",
    title: { nl: "Online Services", en: "Online Services" },
    navigation: { nl: "Online Services", en: "Online Services" },
    color: Color.Purple,
    pages: []
  },
  {
    _id: "b2b",
    title: { nl: "B2B", en: "B2B" },
    navigation: { nl: "B2B", en: "B2B" },
    color: Color.Red,
    pages: []
  },
  {
    _id: "advertising",
    title: { nl: "Advertising & Marketing", en: "Advertising & Marketing" },
    navigation: { nl: "Advertising", en: "Advertising" },
    color: Color.Pink,
    pages: []
  },
  {
    _id: "financieel",
    title: { nl: "Finance", en: "Finance" },
    navigation: { nl: "Finance", en: "Finance" },
    color: Color.FinancialBlue,
    pages: []
  },
];

function getSectionIdByCms(cmsTitle: SectionTitleCms): SectionId | undefined {
  switch (cmsTitle) {
    case "Opening": return "opening";
    case "Voorwoord": return "voorwoord";
    case "Voorpagina": return "voorpagina";
    case "Nieuws": return "nieuws";
    case "Entertainment": return "entertainment";
    case "Online Services": return "onlineservices";
    case "B2B": return "b2b";
    case "Advertising": return "advertising";
    case "Financieel": return "financieel";
  }
}
function getSectionByCms(cmsTitle: SectionTitleCms): IOverviewSection | undefined {
  const id = getSectionIdByCms(cmsTitle);
  if (!id) return undefined;
  return urls.find(s => s._id === id);
}

interface IPrismicPage {
  id: string;
  data: {
    section: SectionTitleCms;
    title: IPrismicText;
    grid: string;
    teasers_left: { teaser: IPrismicTeaser }[];
    teasers_center: { teaser: IPrismicTeaser }[];
    teasers_right: { teaser: IPrismicTeaser }[];
  };
}

interface IPrismicTeaser {
  id: string;
  data: {
    article?: { uid?: string };
    type?: ITeaser["_type"];
    label_font?: FontFamily;
    label_size?: "small" | "large";
    label_nl?: IPrismicText;
    label_en?: IPrismicText;
    title_nl?: IPrismicText;
    title_en?: IPrismicText;
    body_nl?: IPrismicText;
    body_en?: IPrismicText;
    image?: IPrismicImage;
    image_en?: IPrismicImage;
    basic_teaser_image_position?: string;
    basic_teaser_font?: FontFamily;
    image_teaser_horizontal_text_position?: string;
    image_teaser_vertical_text_position?: string;
    image_teaser_text_color?: string;
    image_teaser_bold_word_color?: string;
    image_teaser_text_alignment?: string;
    image_teaser_text_width?: number;
    image_teaser_horizontal_image_position?: string;
    image_teaser_vertical_image_position?: string;
    image_teaser_gradient_opacity?: number;
    image_teaser_gradient_color?: string;
  };
}

// When fetching teasers, include these properties:
const teaserFields = [
  "teaser.article",
  "teaser.label_font",
  "teaser.label_size",
  "teaser.label_nl",
  "teaser.label_en",
  "teaser.title_nl",
  "teaser.title_en",
  "teaser.body_nl",
  "teaser.body_en",
  "teaser.image",
  "teaser.image_en",
  "teaser.type",
  "teaser.basic_teaser_image_position",
  "teaser.basic_teaser_font",
  "teaser.image_teaser_horizontal_text_position",
  "teaser.image_teaser_vertical_text_position",
  "teaser.image_teaser_text_color",
  "teaser.image_teaser_bold_word_color",
  "teaser.image_teaser_text_alignment",
  "teaser.image_teaser_text_width",
  "teaser.image_teaser_horizontal_image_position",
  "teaser.image_teaser_vertical_image_position",
  "teaser.image_teaser_gradient_opacity",
  "teaser.image_teaser_gradient_color",
];

function createSections(pages: IPrismicPage[], language: Language): IOverviewSection[] {
  const sortedPages = _.sortBy(pages, (page: IPrismicPage) => page.data.title[0].text);

  const sections: IOverviewSection[] = [
    {
      _id: "opening",
      title: { nl: "Opening", en: "Opening" },
      navigation: { nl: "Opening", en: "Opening" },
      color: Color.Purple,
      pages: []
    },
    {
      _id: "voorwoord",
      title: { nl: "Voorwoord", en: "Preface" },
      navigation: { nl: "Voorwoord", en: "Preface" },
      color: Color.Pink,
      pages: []
    },
    {
      _id: "voorpagina",
      title: { nl: "Voorpagina", en: "Frontpage" },
      navigation: { nl: "Voorpagina", en: "Frontpage" },
      color: Color.Grey,
      pages: []
    },
    {
      _id: "nieuws",
      title: { nl: "Nieuwsmedia", en: "Newsmedia" },
      navigation: { nl: "Nieuwsmedia", en: "Newsmedia" },
      color: Color.Blue,
      pages: []
    },
    {
      _id: "entertainment",
      title: { nl: "Entertainment Media", en: "Entertainment Media" },
      navigation: { nl: "Entertainment", en: "Entertainment" },
      color: Color.Red,
      pages: []
    },
    {
      _id: "onlineservices",
      title: { nl: "Online Services", en: "Online Services" },
      navigation: { nl: "Online Services", en: "Online Services" },
      color: Color.Purple,
      pages: []
    },
    {
      _id: "b2b",
      title: { nl: "B2B", en: "B2B" },
      navigation: { nl: "B2B", en: "B2B" },
      color: Color.Red,
      pages: []
    },
    {
      _id: "advertising",
      title: { nl: "Advertising & Marketing", en: "Advertising & Marketing" },
      navigation: { nl: "Advertising & Marketing", en: "Advertising & Marketing" },
      color: Color.Pink,
      pages: []
    },
    {
      _id: "financieel",
      title: { nl: "Finance", en: "Finance" },
      navigation: { nl: "Finance", en: "Finance" },
      color: Color.FinancialBlue,
      pages: []
    },
  ];

  let sectionPageIndex = 1;
  let previousSectionId = "";
  const mappedToReadableKeys: IPrismicPage[] = sortedPages.reduce((acc: any, page, index) => {
    if (previousSectionId === page.data.section) {
      sectionPageIndex += 1;
    } else {
      sectionPageIndex = 1;
      previousSectionId = page.data.section;
    }

    const section = getSectionByCms(page.data.section);
    if (!section) {
      throw new Error('Cannot find section ' + page.data.section);
    }
    return [
      ...acc,
      {
        ...page,
        data: {
          ...page.data,
          section: `${section.title[language].replace(/[^a-zA-Z]+/g, '-')}-${sectionPageIndex}`,
        },
      },
    ];
  }, []);

  for (const page of mappedToReadableKeys) {
    const sectionIndex = sections.findIndex((section) => {
      return page.data.section.includes(section.title[language].replace(/[^a-zA-Z]+/g, '-'));
    });
    if (sectionIndex < 0) {
      throw new Error(`Unsupported section: ${page.data.section}`);
    }
    sections[sectionIndex].pages.push(toPage(page));
  }

  return sections.filter((section) => section.pages.length > 0);
}

function toPage(p: IPrismicPage): IOverviewPage {
  return {
    _id: `${p.data.section.replace(" ", "-")}`,
    grid: {
      layout: parseGridLayout(p.data.grid),
      teasers: [
        p.data.teasers_left.map(toTeaser).filter((t): t is ITeaser => !!t),
        p.data.teasers_center.map(toTeaser).filter((t): t is ITeaser => !!t),
        p.data.teasers_right.map(toTeaser).filter((t): t is ITeaser => !!t),
      ],
    },
  };
}

function toTeaser(p: { teaser: IPrismicTeaser }): ITeaser | undefined {
  const { data } = p.teaser;
  if (!data) return;

  switch (data.type) {
    case "basic":
      return {
        _type: "basic",
        articleId: data.article?.uid,
        title: parseText(data.title_nl, data.title_en, FontFamily.sharpSansDisplay),
        body: parseText(data.body_nl, data.body_en, FontFamily.freightTextPro),
        image: parseImage(data.image),
        image_en: parseImage(data.image_en),
        imagePosition: parseVerticalAlignment(data.basic_teaser_image_position) || VerticalAlignment.top,
        fontFamily: data.basic_teaser_font,
      };

    case "title":
      return {
        _type: "title",
        articleId: data.article?.uid,
        title: parseText(data.title_nl, data.title_en, FontFamily.freightTextPro),
      };

    case "360":
    case "image":
      return {
        _type: data.type,
        articleId: data.article?.uid,
        labelFont: data.label_font,
        labelSize: data.label_size,
        label: parseText(data.label_nl, data.label_en, FontFamily.freightTextPro),
        title: parseText(data.title_nl, data.title_en, FontFamily.sharpSansDisplay),
        body: parseText(data.body_nl, data.body_en, FontFamily.freightTextPro),
        image: parseImage(data.image),
        image_en: parseImage(data.image_en),
        textColor: parseColor(data.image_teaser_text_color),
        boldWordColor: data.image_teaser_bold_word_color,
        textAlignment: parseHorizontalAlignment(data.image_teaser_text_alignment),
        textWidth: parsePercentage(data.image_teaser_text_width, 1),
        horizontalTextPosition: parseHorizontalAlignment(data.image_teaser_horizontal_text_position),
        verticalTextPosition: parseVerticalAlignment(data.image_teaser_vertical_text_position),
        horizontalImagePosition: parseHorizontalAlignment(data.image_teaser_horizontal_image_position),
        verticalImagePosition: parseVerticalAlignment(data.image_teaser_vertical_image_position),
        gradientColor: parseColor(data.image_teaser_gradient_color),
        gradientOpacity: parsePercentage(data.image_teaser_gradient_opacity, 0),
      };

    default:
      throw new Error(`Unsupported teaser: ${data.type}`);
  }
}
